import React from 'react';

const Header = ({ theme }) => {
  const guildLogo = theme === 'dark' ? '/img/guild_logo_dark.png' : '/img/guild_logo_light.png';

  return (
    <header className="w-full bg-white shadow-lg backdrop-blur-xl bg-opacity-10 z-99">
      <div className="max-w-7xl mx-auto p-4 flex items-center justify-between">
        <img src={guildLogo} alt="Guild Logo" className="h-10 rounded-lg" />
        
        <div className="relative inline-block h-10 rounded-lg">
          <img 
            src="/img/anchor_logo.png" 
            alt="Anchor Logo" 
            className="h-10 rounded-lg relative z-10" 
          />
          <div 
            className="absolute top-0 left-0 w-full h-full z-0 rounded-lg" 
            style={{
              filter: 'drop-shadow(2px 2px 6px #000a)',
              maskImage: 'url(/img/anchor_logo.png)',
              WebkitMaskImage: 'url(/img/anchor_logo.png)'
            }}
          ></div>
        </div>

      </div>
    </header>
  );
};

export default Header;
