import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header'; // 导入Header组件
import config from '../config'; 

const SongList = () => {
  const [data, setData] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    axios.post('https://api.kyom.cn/songlist', {
      uid: config.uid
    })
    .then(response => {
      setData(response.data);
      if (response.data.songs && response.data.songs.length > 0) {
        setBackgroundImage(response.data.songs[0].banner_img.url);
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div 
      className="min-h-screen relative font-sans" 
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      {/* 添加全屏模糊遮罩 */}
      <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-0"></div>

      <Header theme="dark" />
      <div className="flex flex-col items-center p-6 relative z-10">
        <img src={data.face} alt={data.uname} className="w-40 h-40 rounded-full shadow-lg mb-4" />
        <h1 className="text-3xl font-bold text-white">{data.uname}</h1>
        <p className="text-white text-sm mt-3">房间号: {data.roomid}</p>
        <p className="text-white text-sm">UID: {data.uid}</p>

        <div className="mt-6 w-full max-w-4xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {data.songs.map(song => (
              <div key={song.id} className="relative rounded-lg overflow-hidden shadow-lg">
                <img src={song.banner_img.url} alt={song.name} className="w-full h-56 object-cover" />
                <div className="absolute inset-x-0 bottom-0 bg-transparent p-4 flex flex-col justify-end">
                  <div className="backdrop-blur-lg bg-black bg-opacity-30 rounded-lg p-2">
                    <h2 className="text-white text-xl font-bold">{song.name}</h2>
                    <p className="text-white text-sm font-semibold">{song.introduction}</p>
                  </div>
                </div>
                <a href={`/song/${song.id}`} className="absolute inset-0 z-10"></a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SongList;
